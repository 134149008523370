import '@stimulus/polyfills'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import Chart from 'stimulus-chartjs'

function importAll (r) {
  r.keys().forEach(r)
}
importAll(require.context('../../components/site', true, /desktop\/component\.(js|ts)$/))
importAll(require.context('../../components/site', true, /common\/component\.(js|ts)$/))
importAll(require.context('../../components/site/common', true, /component\.(js|ts)$/))
importAll(require.context('../src/common', true, /.*\.(js|ts)$/))

const application = Application.start()
application.register('chart', Chart)
application.load(definitionsFromContext(require.context('../../components/site', true, /desktop\/component_controller\.(js|ts)$/)))
application.load(definitionsFromContext(require.context('../../components/site', true, /common\/component_controller\.(js|ts)$/)))
application.load(definitionsFromContext(require.context('../../components/site/common', true, /component_controller\.(js|ts)$/)))

export const images = require.context('../images', true)
