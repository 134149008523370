import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  get className () {
    return this.element.dataset.classname
  }

  toggle () {
    if (this.element.classList.contains(`${this.className}::accordion-close`)) {
      this.open()
    } else {
      this.close()
    }
  }

  open () {
    this.element.classList.remove(`${this.className}::accordion-close`)
    this.element.classList.add(`${this.className}::accordion-open`)
  }

  close () {
    this.element.classList.remove(`${this.className}::accordion-open`)
    this.element.classList.add(`${this.className}::accordion-close`)
  }
}
