import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  copy (event) {
    event.preventDefault()
    const textArea = document.createElement('textarea')
    textArea.value = event.target.dataset.clipboardTarget
    textArea.display = 'none'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }
}
