import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['moreButton', 'moreText']

  more () {
    this.moreButtonTarget.style.display = 'none'
    this.moreTextTarget.style.display = 'inline'
  }
}
