import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Thumbs } from 'swiper/core'

const DESKTOP_WIDTH = 1000

Swiper.use([Navigation, Thumbs])

export default class extends Controller {
  static targets = ['large', 'small', 'scrollArea', 'table']

  get className () {
    return this.element.dataset.classname
  }

  connect () {
    this.slider()

    if (this.tableTarget.clientWidth > DESKTOP_WIDTH) {
      this.scrollWheel()
      this.scrollDrag()
    }
  }

  slider () {
    const thumbs = new Swiper(this.smallTarget, {
      loop: false,
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
    })

    const slider = new Swiper(this.largeTarget, {
      loop: false,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: thumbs,
      },
    })

    const changeActiveCell = slider => {
      this.tableTarget.querySelectorAll('tr').forEach(trElement => {
        trElement.querySelectorAll('th, td').forEach(element => element.classList.remove(`${this.className}::active`))
        const th = trElement.querySelector(`th:nth-of-type(${slider.activeIndex + 2})`)
        const td = trElement.querySelector(`td:nth-of-type(${slider.activeIndex + 1})`)
        if (th) th.classList.add(`${this.className}::active`)
        if (td) td.classList.add(`${this.className}::active`)
      })
    }

    slider.on('activeIndexChange', () => {
      changeActiveCell(slider)
    })
    changeActiveCell(slider)
  }

  scrollWheel () {
    const mouseWheelHandler = e => {
      const scrollValue = 15
      const position = this.scrollAreaTarget.scrollLeft + (e.wheelDelta > 0 ? -scrollValue : scrollValue)
      this.scrollAreaTarget.scrollLeft = position
    }

    this.scrollAreaTarget.addEventListener('wheel', mouseWheelHandler)
  }

  scrollDrag () {
    let pos = { top: 0, left: 0, x: 0, y: 0 }

    const mouseMoveHandler = (e) => {
      const dx = e.clientX - pos.x
      this.scrollAreaTarget.scrollLeft = pos.left - dx
    }

    const mouseDownHandler = (e) => {
      this.scrollAreaTarget.style.cursor = 'grabbing'
      this.scrollAreaTarget.style.userSelect = 'none'

      pos = {
        left: this.scrollAreaTarget.scrollLeft,
        top: this.scrollAreaTarget.scrollTop,
        x: e.clientX,
        y: e.clientY,
      }

      this.scrollAreaTarget.addEventListener('mousemove', mouseMoveHandler)
      this.scrollAreaTarget.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseUpHandler = () => {
      this.scrollAreaTarget.style.cursor = 'grab'
      this.scrollAreaTarget.style.removeProperty('user-select')

      this.scrollAreaTarget.removeEventListener('mousemove', mouseMoveHandler)
      this.scrollAreaTarget.removeEventListener('mouseup', mouseUpHandler)
    }

    this.scrollAreaTarget.style.cursor = 'grab'
    this.scrollAreaTarget.addEventListener('mousedown', mouseDownHandler)
  }
}
