import { Controller } from '@hotwired/stimulus'

const VISIBLE_POSITION = 400

export default class extends Controller {
  initialize () {
    let lastScrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleVisibility(lastScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })
  }

  get className () {
    return this.element.dataset.classname
  }

  toggleVisibility (scrollPosition) {
    if (scrollPosition > VISIBLE_POSITION) {
      this.element.classList.add(`${this.className}::visible`)
    } else {
      this.element.classList.remove(`${this.className}::visible`)
    }
  }
}
