import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accordion', 'show', 'hide']

  connect () {
    this.accordionTargets.forEach((element, _index) => {
      if (element.clientHeight >= element.dataset.height) {
        this.showTarget.style.display = 'flex'
        this.hideTarget.style.display = 'none'
      } else {
        this.showTarget.style.display = 'none'
        this.hideTarget.style.display = 'none'
      }
    })
  }

  show (e) {
    e.target.previousSibling.style.display = 'flex'
    e.target.style.display = 'none'
    this.accordionTargets[1].style.maxHeight = 'none'
  }

  hide (e) {
    e.target.nextSibling.style.display = 'flex'
    e.target.style.display = 'none'
    this.accordionTargets[1].style.maxHeight = '250px'
  }
}
